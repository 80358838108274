.find-out-more {
  .contact-p {
    max-width: 80%;

    a {
      font-weight: bold;
      color: #333;
    }
  }

  form {
    input,
    textarea {
      width: 100%;
    }

    button {
      margin-top: 1rem;
    }
  }

  .error {
    font-weight: 600;
    color: #fff;

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      background-color: rgba($color: #bb2528, $alpha: 0.35);
      border-left: 4px solid #bb2528;
      padding: 0 1rem;
    }

    &-animation {
      -webkit-animation: kf_shake 0.4s 1 linear;
      -moz-animation: kf_shake 0.4s 1 linear;
      -o-animation: kf_shake 0.4s 1 linear;
      animation: kf_shake 0.4s 1 linear;
    }
  }
}

@-webkit-keyframes kf_shake {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}
@-moz-keyframes kf_shake {
  0% {
    -moz-transform: translate(30px);
  }
  20% {
    -moz-transform: translate(-30px);
  }
  40% {
    -moz-transform: translate(15px);
  }
  60% {
    -moz-transform: translate(-15px);
  }
  80% {
    -moz-transform: translate(8px);
  }
  100% {
    -moz-transform: translate(0px);
  }
}
@-o-keyframes kf_shake {
  0% {
    -o-transform: translate(30px);
  }
  20% {
    -o-transform: translate(-30px);
  }
  40% {
    -o-transform: translate(15px);
  }
  60% {
    -o-transform: translate(-15px);
  }
  80% {
    -o-transform: translate(8px);
  }
  100% {
    -o-origin-transform: translate(0px);
  }
}
@keyframes kf_shake {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}
