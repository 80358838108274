@media only screen and (min-width: 992px) {
  .grid {
    padding: 10rem 0;
    display: grid;
    grid-column-gap: 10px;
    width: 100%;

    &--3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .grid--2 {
    padding: 10rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    width: 100%;
  }
}
