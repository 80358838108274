.features {
  background-color: #f1f4f6;
  padding: 3rem 0;
}

h2 {
  font-size: 3rem;
  line-height: 3.5rem;
  margin: 0;
  padding: 0;
}

.highlight {
  color: #7ca870;
}
