footer {
  background-color: #f1f4f6;
  padding: 2rem 0;

  .grid--2 {
    padding: 0 0;

    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
    }

    @media only screen and (min-width: 992px) {
      ul {
        text-align: right;
      }
    }
  }
}