.work-with-us {
    background-color: #7ca870;
    color: #fff;
    padding: 9rem 0;
    margin-top: 8rem;

    h2 {
        font-size: 5.5rem;
        line-height: 5rem;
        margin-bottom: 7rem;
    }

    a {
        text-decoration: none;
    }

    ol {
        list-style: none;
        padding: 0;
        margin: 0;
        grid-column-gap: 5rem;

        li {
            position: relative;
            margin-top: 7rem;

            h3 {
                font-size: 0.9rem;
                text-transform: uppercase;
                z-index: 5;
                margin: 0;
                margin-bottom: 0.5rem;
            }

            h4 {
                font-size: 2.5rem;
                z-index: 5;
                margin: 0;
                margin-bottom: 4rem;
            }

            p {
                margin: 0;
                padding: 0;
                margin-bottom: 2rem;
                z-index: 5;
            }

            .step {
                &__number {
                    font-size: 17rem;
                    left: auto;
                    right: 0;
                    top: -4.5rem;
                    transform: translate(0, -70px);
                    z-index: 2;
                    position: absolute;
                    color: rgb(160, 197, 150);
                    font-family: "Baloo Bhaina 2", cursive;
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .work-with-us {
        margin-top: 18rem;

        ol {
            li {
                margin-top: 0;
            }
        }
    }
}
