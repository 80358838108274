.logo {
  font-family: "Baloo Bhaina 2", cursive;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 2.8125rem;
  color: #333;
  margin: 0;
  padding: 0;
  display: inline-block;

  .highlight {
    color: #7ca870;
  }
}
