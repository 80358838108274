.container {
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.hide-for {
  &-mobile {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.margin-right {
  margin-right: 1rem !important;
}

.padding {
  &-top--none {
    padding-top: 0 !important;
  }

  &-bottom--none {
    padding-bottom: 0 !important;
  }
}

.todo {
  display: inline-block;
  background-color: #fd0;
  color: #333;
  padding: 5px 10px;
}

@media only screen and (max-width: 1280px) {
  .container {
    max-width: 1080px;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 900px;
  }
}
