.why-us {
  background-color: #7ca870;
  color: #fff;

  .grid--2 {
    padding: 4rem 2rem;
  }

  h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.618rem;
  }

  .grid--list {
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    width: 100%;
    font-weight: 600;
    text-align: center;

    span {
      display: block;
    }

    svg {
      font-size: 4rem;
    }
  }
}

.line-break-bottom {
  background-color: #f1f4f6;
}

.icons-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 992px) {
  .line-break-top,
  .line-break-bottom {
    svg {
      width: 100%;
    }
  }

  .why-us {
    .grid--2 {
      padding: 10rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      width: 100%;
    }

    .grid--list {
      padding: 1rem 0 10rem 0;
      grid-template-columns: repeat(6, 1fr);
    }

    h1 {
      font-size: 4rem;
      line-height: 4.5rem;
      margin-bottom: 4rem;
    }

    p {
      width: 100%;
      padding: 0;
      font-size: 1.25rem;
    }
  }
}
