.home {
    .grid--2 {
        padding-bottom: 2rem;
    }

    &__section {
        &__container {
            margin-bottom: 4rem;
        }

        margin-top: 4rem;

        h2 {
            font-size: 2.5rem;
            line-height: 2.75rem;
        }

        p {
            font-size: 1.25rem;
            margin-top: 2.5rem;
        }

        span {
            display: block;
            margin-bottom: 1rem;
            font-weight: 500;
            color: #7ca870;
            font-size: 1.25rem;
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 80%;
            margin-top: 3rem;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                font-weight: 600;
                margin-bottom: 1rem;

                svg {
                    margin-right: 1rem;
                    color: #7ca870;
                }
            }
        }

        &__highlights {
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style: none;
                    font-weight: 600;
                    margin-bottom: 1.5rem;
                    display: flex;

                    svg {
                        display: block;
                        margin-right: 1rem;
                        color: #7ca870;
                        position: relative;
                        top: 0.5rem;
                    }
                }
            }
        }
    }

    .img__scroll {
        position: relative;

        &__container {
            position: relative;
            top: 125px;
            z-index: -3;
            left: 15px;
            margin-top: -100px;

            img {
                height: 75px;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .home {
        &__section {
            &__container {
                margin-bottom: 9rem;
            }

            margin-top: 9rem;

            h2 {
                font-size: 4rem;
                line-height: 4.2rem;
            }

            p {
                font-size: 1.25rem;
                margin-top: 2.5rem;
            }

            ul {
                margin-left: 5rem;
            }

            img {
                margin: 0;
                margin-left: 50px;
                max-height: 377px;
            }

            &__highlights {
                .grid {
                    grid-column-gap: 3rem;
                }
            }
        }
    }
}
