.navbar-wrapper {
  border-bottom: 1px solid #e4e6e7;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  background-color: #fff;
  z-index: 999999;

  nav {
    padding: 1rem;

    .logo {
      position: relative;
      top: 5px;
    }
  }
}
