button,
.button {
  padding-right: 20px;
  padding-left: 20px;
  min-height: 42px;
  font-size: 12px;
  background-color: #f1f1f1;
  color: #333;
  position: relative;
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  outline: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 25px;
  box-shadow: none;
  vertical-align: middle;
  text-align: center;
  text-transform: none;
  text-shadow: none;
  white-space: unset;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 1.2;
  cursor: pointer;
  transition: all 0.25s ease;
  font-weight: 600;
  text-decoration: none;
  width: 100%;
  border: 2px solid #f1f1f1;
  letter-spacing: 2px;
}

.button--primary {
  background-color: $color-primary;
  border: 2px solid $color-primary;
  color: #fff;

  &:hover {
    background-color: $color-primary-light;
  }
}

.button--secondary {
  background-color: $color-white;
  border: 2px solid $color-white;
  color: $color-primary;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;

  &:hover {
    background-color: $color-primary-light;
    color: #fff;
  }
}

.button-group {
  button, .button {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 992px) {
  .button, button {
    width: unset;
  }

  .button-group {
    button, .button {
      margin-bottom: 0rem;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
