.hero {
  background-color: #7ca870;

  .container {
    padding: 4rem 2rem;
  }

  a {
    text-decoration: none;
  }

  h1 {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.03125rem;
    line-height: 24px;
    margin-bottom: 24px;
  }

  h2 {
    color: #fff;
    font-weight: 700;
    font-size: 2.75rem;
    line-height: 2.75rem;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
  }

  .slider__container {
    margin-top: 1.5rem;
  }

  .before-after-slider__delimer-icon {
    background-color: #fff;
    border: 5px solid #7ca870;
  }

  .before-after-slider__delimer {
    background-color: #7ca870;
  }

  .before-after-slider__delimer,
  .before-after-slider__delimer-icon {
    &::before,
    &::after {
      animation: 2s ease 0s infinite normal none running pulse;
      border-radius: 50%;
      border: 1px solid #7ca870;
      content: "";
      height: 32px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0px);
      width: 32px;
      z-index: 1;
    }

    &::after {
      animation-delay: 0.5s;
    }
  }
}

@media only screen and (min-width: 992px) {
  .hero {
    .left {
      margin: 4rem 0;

      h2 {
        font-size: 5.5rem;
        line-height: 5.25rem;
      }
    }

    .slider__container {
      margin-top: 4.5rem;
    }

    .grid--2 {
      padding: 10rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      width: 100%;
    }

    .before-after-slider__delimer {
      height: 120%;
      top: -2rem;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .hero {
    .left {
      h2 {
        font-size: 4.75rem;
        line-height: 4.75rem;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .hero {
    .left {
      h2 {
        font-size: 3.5rem;
        line-height: 3.5rem;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    height: 32px;
    opacity: 1;
    width: 32px;
  }
  80% {
    height: 86px;
    opacity: 0;
    width: 86px;
  }
  100% {
    height: 32px;
    opacity: 0;
    width: 32px;
  }
}
@keyframes pulse {
  0% {
    height: 32px;
    opacity: 1;
    width: 32px;
  }
  80% {
    height: 86px;
    opacity: 0;
    width: 86px;
  }
  100% {
    height: 32px;
    opacity: 0;
    width: 32px;
  }
}
